<template>
    <div class="contact-address">
        <div class="block-title contact-address-title">{{ i18nPage.title }}</div>

        <div class="contact-address-list">
            <div class="contact-address-item" :class="{ full: item.info.length > 1 }" v-for="(item, index) in i18nPage.list" :key="index">
                <div class="poppins contact-address-item-title">{{ item.address }}</div>

                <div class="contact-address-item-info">
                    <div class="contact-address-item-info-item" v-for="(info, i) in item.info" :key="i">
                        {{ info }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        i18nPage() {
            return this.$t('contactPage.officeAddress');
        },
    },
};
</script>

<style lang="scss">
.contact-address {
    @media only screen and (min-width: 769px) {
        width: 683px;
        margin: 0 auto;
        padding-top: 80px;
        padding-bottom: 60px;

        &-title {
            text-align: left;
            margin-bottom: 40px;
        }
        &-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        &-item {
            width: 308px;
            margin-bottom: 40px;

            &.full {
                width: 100%;
            }

            &-title {
                font-size: 16px;
                line-height: 22px;
                font-weight: 700;
                margin-bottom: 8px;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    width: 3px;
                    height: 25px;
                    background: #00c8c8;
                    left: -13px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &-info {
                display: flex;
                justify-content: space-between;

                &-item {
                    width: 308px;
                    font-size: 14px;
                    line-height: 22px;
                    color: rgba(0, 0, 0, 0.5);
                }
            }
        }
    }
    @media only screen and (max-width: 768px) {
        padding-top: 0.8rem;
        padding-bottom: 0.4rem;
        &-title {
            font-size: 0.32rem;
            line-height: 1;
            padding: 0 0.38rem;
            margin-bottom: 0.42rem;
        }
        &-list {
            padding-left: 0.38rem;
            padding-right: 0.3rem;
        }

        &-item {
            margin-bottom: 0.42rem;
            &-title {
                margin-bottom: 0.08rem;
                position: relative;
                font-size: 0.16rem;
                line-height: 0.22rem;
                font-weight: 700;

                &:before {
                    content: '';
                    width: 3px;
                    height: 0.25rem;
                    left: -0.13rem;
                    top: 50%;
                    transform: translateY(-50%);
                    background: #00c8c8;
                    position: absolute;
                }
            }
            &-info {
                font-size: 0.14rem;
                line-height: 0.22rem;
                color: rgba(0, 0, 0, 0.5);

                &-item {
                    margin-bottom: 0.24rem;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
</style>
