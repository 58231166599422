<template>
    <div class="Page contact">
        <top @formSubmit="formSubmit" v-if="!isShowMeeting"></top>
        <meeting :contactData="contactData" v-else></meeting>
        <officeAddress></officeAddress>
    </div>
</template>

<script>
import top from './components/top.vue';
import meeting from './components/meeting.vue';
import officeAddress from './components/officeAddress.vue';

export default {
    components: {
        top,
        meeting,
        officeAddress,
    },
    data() {
        return {
            contactData: {},
            isShowMeeting: false,
            pageDurationID: 'ContactUs',
        };
    },
    methods: {
        formSubmit(data) {
            this.contactData = data;
            this.isShowMeeting = true;
            this.$track('meeting_page_exp');
        },
    },
    mounted() {
        this.$track('contactus_imp');
        var params = this.$route.params || {};
        if (params.showMeeting) {
            this.isShowMeeting = true;
            this.contactData = params.data;
        }
    },
};
</script>
