<template>
    <div class="contact-meeting">
        <div class="poppins block-title contact-meeting-title">{{ i18nPage.title }}</div>
        <div class="block-desc contact-meeting-desc1">{{ i18nPage.desc1 }}</div>
        <div class="block-desc contact-meeting-desc2">{{ i18nPage.desc2 }}</div>

        <div class="contact-meeting-main" v-if="!isMeetingSuccess">
            <div class="contact-meeting-main-title">{{ i18nPage.time1 }}</div>
            <div class="contact-meeting-main-time1">{{ time1 }}</div>

            <div class="date-pick">
                <div class="date-pick-button prev" :class="{ disabled: dayPage <= 0 }" @click="prev">
                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 10L.757 5.757 5.07 1.585" stroke="#595959" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </div>
                <div class="date-pick-main">
                    <div class="date-pick-item" :class="{ enable: item.enable, active: item.timestamp === currentDay.timestamp }" v-for="item in dateList" :key="item.timestamp" @click="onDayClick(item)">
                        <div class="day">{{ item.day }}</div>
                        <div class="weekday">{{ i18nPage.weekdays[item.weekday] }}</div>
                    </div>
                </div>
                <div class="date-pick-button next" :class="{ disabled: dayPage >= 5 }" @click="next">
                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 10l4.243-4.243L.93 1.585" stroke="#595959" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </div>
            </div>

            <div class="contact-meeting-main-title">{{ i18nPage.time2 }}</div>
            <div class="contact-meeting-main-time1">{{ i18nPage.meeting30 }} · {{ timezone }}</div>

            <div class="time-pick">
                <div class="time-pick-item" :class="{ active: item.id === currentTime.id }" v-for="item in timeList" :key="item.id" @click="onTimeClick(item)">{{ item.text }}</div>
            </div>

            <div class="contact-meeting-main-foot">
                <a href="javascript:;" class="poppins submit" :class="{ disabled: !currentDay.timestamp || !currentTime.id }" @click="onSubmit">{{ $t('contactPage.top.submit') }}</a>
            </div>
        </div>

        <div class="contact-meeting-success" v-else>
            <svg class="icon" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="6" y="12" width="52" height="42" rx="4" stroke="#1C2233" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M18 8v8M46 8v8M6 22h52" stroke="#1C2233" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M25 35.464l5.898 5.898 9.464-9.465" stroke="#1FC8D2" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"/></svg>

            <div class="poppins contact-meeting-success-title">{{ i18nPage.success_title }}</div>

            <div class="contact-meeting-success-item">
                <div class="label">{{ i18nPage.date }}</div>
                <div class="value">{{ currentDay.year }}-{{ currentDay.month }}-{{ currentDay.day }}</div>
            </div>

            <div class="contact-meeting-success-item">
                <div class="label">{{ i18nPage.time }}</div>
                <div class="value">{{ currentTime.text2 }}</div>
            </div>

            <div class="contact-meeting-success-notice">
                <p>{{ i18nPage.notice1 }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import qs from 'qs';
import http from '@utils/http';
export default {
    data() {
        return {
            isMeetingSuccess: false,
            currentDay: {},
            currentTime: {},
            dayPage: 0,
            minTimestamp: null,
            current: null, // 当前选中日期时间戳
            dateList: [],
            timeList: [
                {
                    id: '10',
                    hour: 10,
                    minutes: '00',
                    text: '10:00 AM',
                    text2: '10:00 AM - 10:30 AM',
                },
                {
                    id: '10.5',
                    hour: 10,
                    minutes: '30',
                    text: '10:30 AM',
                    text2: '10:30 AM - 11:00 AM',
                },
                {
                    id: '11',
                    hour: 11,
                    minutes: '00',
                    text: '11:00 AM',
                    text2: '11:00 AM - 11:30 AM',
                },
                {
                    id: '14',
                    hour: 14,
                    minutes: '00',
                    text: '02:00 PM',
                    text2: '02:00 PM - 02:30 PM',
                },
                {
                    id: '14.5',
                    hour: 14,
                    minutes: '30',
                    text: '02:30 PM',
                    text2: '02:30 PM - 03:00 PM',
                },
                {
                    id: '15',
                    hour: 15,
                    minutes: '00',
                    text: '03:00 PM',
                    text2: '03:00 PM - 03:30 PM',
                },
                {
                    id: '15.5',
                    hour: 15,
                    minutes: '30',
                    text: '03:30 PM',
                    text2: '03:30 PM - 04:00 PM',
                },
                {
                    id: '16',
                    hour: 16,
                    minutes: '00',
                    text: '04:00 PM',
                    text2: '04:00 PM - 04:30 PM',
                },
                {
                    id: '16.5',
                    hour: 16,
                    minutes: '30',
                    text: '04:30 PM',
                    text2: '04:30 PM - 05:00 PM',
                },
                {
                    id: '17',
                    hour: 17,
                    minutes: '00',
                    text: '05:00 PM',
                    text2: '05:00 PM - 05:30 PM',
                },
                {
                    id: '17.5',
                    hour: 17,
                    minutes: '30',
                    text: '05:30 PM',
                    text2: '05:30 PM - 06:00 PM',
                },
                {
                    id: '18',
                    hour: 18,
                    minutes: '00',
                    text: '06:00 PM',
                    text2: '06:00 PM - 06:30 PM',
                },
            ],
        };
    },
    props: {
        contactData: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        timezone() {
            var timezone = '';
            try {
                timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            } catch (error) {
                console.error('GET TIMEZONE ERROR', error);
            }
            return timezone;
        },
        i18nPage() {
            return this.$t('contactPage.meeting');
        },
        time1() {
            var date = new Date();
            if (this.currentDay.timestamp) {
                date = new Date(this.currentDay.timestamp);
            }
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
            }
            return year + '-' + month;
        },
    },
    methods: {
        onSubmit() {
            this.$track('meeting_submit_button_clk');
            if (this.currentDay.timestamp && this.currentTime.id) {

                var data = {
                    page_url: this.contactData.page_url,
                    _ga: this.contactData._ga,
                    _gid: this.contactData._gid,
                    url: process.env.VUE_APP_MEETING,
                    email: this.contactData.email,
                    date: this.currentDay.year + '-' + this.currentDay.month + '-' + this.currentDay.day,
                    hour: this.currentTime.hour,
                    minutes: this.currentTime.minutes,
                    timezone: this.timezone,
                };
                console.log(data);

                let api = `${process.env.VUE_APP_API_HOST}h5/proxy_post`;
                if (this.isSubmiting) return;
                this.isSubmiting = true;

                http.post(api, qs.stringify(data))
                    .then(() => {
                        this.isMeetingSuccess = true;
                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                        this.$track('meeting_submit_success_page_exp');
                        this.$nextTick(() => {
                            this.isSubmiting = false;
                        });
                    })
                    .catch(error => {
                        this.isSubmiting = false;
                        console.error(error);
                    });
            }
        },
        prev() {
            if (this.dayPage > 0) {
                this.dayPage = this.dayPage - 1;
                this.getDateList(+new Date() + 1000 * 60 * 60 * 24 * 7 * this.dayPage);
            }
        },
        next() {
            if (this.dayPage <= 4) {
                this.dayPage = this.dayPage + 1;
                this.getDateList(+new Date() + 1000 * 60 * 60 * 24 * 7 * this.dayPage);
            }
        },
        onDayClick(data) {
            if (data.enable) {
                this.currentDay = data;
            }
        },
        onTimeClick(data) {
            this.currentTime = data;
        },
        // 获取最早可预约日期(产品需求 当天后第三个工作日开始)
        getMinTime() {
            var count = 3;
            var date = new Date();
            while (count > 0) {
                date = new Date(date.getTime() + 1000 * 60 * 60 * 24);
                var weekday = date.getDay();
                if (weekday !== 6 && weekday !== 0) {
                    count--;
                }
            }
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var day = date.getDate();
            this.minTimestamp = +new Date(`${year}/${month}/${day} 00:00:00`);
            console.log(date);
        },
        // 生成最近7天列表
        getDateList(timestamp) {
            if (!timestamp) {
                var d = new Date();
                let _year = d.getFullYear();
                let _month = d.getMonth() + 1;
                let _date = d.getDate();
                timestamp = +new Date(`${_year}/${_month}/${_date} 00:00:00`);
            }
            let result = [];
            console.log('minTimestamp', this.minTimestamp);
            for (var i = 0; i < 7; i++) {
                let date = new Date(timestamp + 1000 * 60 * 60 * 24 * i);
                var weekday = date.getDay();
                var month = date.getMonth() + 1;
                var year = date.getFullYear();
                var day = date.getDate();
                if (month < 10) {
                    month = '0' + month;
                }
                if (day < 10) {
                    day = '0' + day;
                }
                result.push({
                    timestamp: +new Date(`${year}/${month}/${day} 00:00:00`),
                    weekday,
                    year,
                    month,
                    day,
                    enable: weekday !== 6 && weekday !== 0 && date.getTime() >= this.minTimestamp,
                });
            }
            console.log(result);
            this.dateList = result;
        },
    },
    mounted() {
        this.getMinTime();
        this.getDateList();
    },
};
</script>

<style lang="scss">
.contact-meeting {
    user-select: none;
    background-color: #f9f9f9;

    &-title {
        color: #00C8C8;
    }

    &-success {
        margin: 0 auto;
        background: #fff;
    }

    @media only screen and (min-width: 769px) {
        padding-top: 80px;
        padding-bottom: 50px;

        &-desc2 {
            margin: 0 auto !important;
        }
        &-main {
            width: 800px;
            margin: 40px auto 0 auto;
            background: #fff;
            border-radius: 20px;
            padding: 48px;
            box-sizing: border-box;

            &-title {
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
            }
            &-time1 {
                font-size: 12px;
                color: #8C8C8C;
                font-weight: 500;
                line-height: 22px;
            }

            .date-pick {
                display: flex;
                align-items: center;
                margin-top: 16px;
                margin-bottom: 24px;

                &-button {
                    width: 48px;
                    height: 48px;
                    box-sizing: border-box;
                    background: #F5F7F9;
                    border-radius: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-shrink: 0;
                    cursor: pointer;

                    &.disabled {
                        cursor: not-allowed;
                    }
                }

                &-main {
                    flex: 1;
                    display: flex;
                    flex-shrink: 0;
                    padding: 0 6px;
                    justify-content: space-between;
                }
                &-item {
                    flex-shrink: 0;
                    width: 80px;
                    height: 90px;
                    // margin: 0 3px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    cursor: not-allowed;
                    border: solid 1px transparent;
                    border-radius: 4px;

                    .day {
                        color: #BFBFBF;
                        font-size: 32px;
                        font-weight: 600;
                        line-height: 1;
                    }
                    .weekday {
                        color: #BFBFBF;
                        font-size: 12px;
                        transform: scale(0.833333);
                    }

                    &.enable {
                        cursor: pointer;
                        .day {
                            color: #595959;
                        }
                        .weekday {
                            color: #8c8c8c;
                        }

                        &:hover {
                            border: solid 1px #1FC8D2;
                            background: #F0FFFD;
                        }
                    }
                    &.active {
                        background: #1FC8D2 !important;
                        .day,
                        .weekday {
                            color: #fff;
                        }
                    }
                }
            }

            .time-pick {
                display: flex;
                flex-wrap: wrap;
                margin: -4px;
                padding-top: 16px;
                padding-bottom: 40px;

                &-item {
                    width: 168px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #D9D9D9;
                    border-radius: 4px;
                    margin: 4px;
                    font-size: 14px;
                    color: #595959;
                    cursor: pointer;
                    font-weight: 500;

                    &:hover {
                        background: #F0FFFD;
                        border: 1px solid #1FC8D2;
                    }
                    &.active {
                        background: #1FC8D2;
                        border: solid 1px #1FC8D2;
                        color: #fff;
                        font-weight: 600;
                    }
                }
            }

            &-foot {
                display: flex;

                .submit {
                    height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: 600;
                    padding: 0 40px;
                    border: solid 1px #1FC8D2;
                    border-radius: 23px;
                    margin: 0 auto;
                    color: #1FC8D2;

                    &.disabled {
                        color: #000;
                        border: solid 1px #000;
                        cursor: not-allowed;
                    }
                }
            }
        }
        &-success {
            width: 460px;
            box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
            border-radius: 20px;
            margin-top: 80px;
            padding: 32px;
            box-sizing: border-box;

            .icon {
                display: block;
                margin: 0 auto;
            }

            &-title {
                font-size: 20px;
                font-weight: 500;
                text-align: center;
                margin-bottom: 28px;
                margin-top: 32px;
            }

            &-item {
                font-size: 16px;
                display: flex;
                align-items: center;
                margin: 4px 0;
                .label {
                    width: 130px;
                    text-align: right;
                    color: #8C8C8C;
                    font-weight: 500;
                    margin-right: 24px;
                }
                .value {
                    font-weight: 600;
                }
            }

            &-notice {
                padding: 28px 0;
                font-size: 12px;
                color: rgba(0, 0, 0, .5);
                text-align: center;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        padding-top: 0.4rem;
        overflow: hidden;
        &-title {
            font-size: 0.28rem;
            text-align: center;
            margin-bottom: 0.06rem;
        }
        &-desc1,
        &-desc2 {
            font-size: 0.12rem;
            text-align: center;
        }

        &-main {
            background: #fff;
            margin-top: 0.32rem;
            padding: 0.24rem 0.16rem 0.32rem 0.16rem;

            &-title {
                font-size: 0.14rem;
                line-height: 0.24rem;
                font-weight: 600;
            }
            &-time1 {
                font-size: 0.12rem;
                line-height: 0.22rem;
                color: #8c8c8c;
                font-weight: 500;
            }

            .date-pick {
                display: flex;
                align-items: center;
                margin-bottom: 0.16rem;
                margin-top: 0.14rem;

                &-button {
                    width: 0.24rem;
                    height: 0.24rem;
                    flex-shrink: 0;
                    background: #F5F7F9;
                    border-radius: 0.24rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        width: 3px;
                    }

                    &.disabled {
                        opacity: 0.6;
                    }
                }
                &-main {
                    display: flex;
                    flex: 1;
                    padding: 0 0.06rem;
                    justify-content: space-around;
                }
                &-item {
                    width: 0.4rem;
                    height: 0.44rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;

                    .day {
                        font-size: 0.16rem;
                        line-height: 1;
                        font-weight: 600;
                        color: #bfbfbf;
                        margin-top: 0.06rem;
                    }
                    .weekday {
                        font-size: 0.12rem;
                        transform: scale(0.416666);
                        color: #8c8c8c;
                        margin-top: -0.04rem;
                    }

                    &.enable {
                        .day {
                            color: #595959;
                        }
                    }

                    &.active {
                        background: #1FC8D2;
                        border-radius: 2px;

                        .day,
                        .weekday {
                            color: #fff;
                        }
                    }
                }
            }

            .time-pick {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                margin: -0.05rem;
                padding-top: 0.14rem;
                padding-bottom: 0.32rem;

                &-item {
                    width: 1.07rem;
                    height: 0.44rem;
                    border: solid 1px #d9d9d9;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 0.14rem;
                    font-weight: 500;
                    margin: 0.05rem;
                    box-sizing: border-box;

                    &.active {
                        background: #1FC8D2;
                        border-color: #1FC8D2;
                        color: #fff;
                    }
                }
            }
            &-foot {
                display: flex;

                .submit {
                    height: 0.45rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 0.16rem;
                    font-weight: 600;
                    padding: 0 0.4rem;
                    border: solid 1px #1FC8D2;
                    border-radius: 0.4rem;
                    margin: 0 auto;
                    color: #1FC8D2;

                    &.disabled {
                        color: #000;
                        border: solid 1px #000;
                        cursor: not-allowed;
                    }
                }
            }
        }

        &-success {
            width: 3.28rem;
            box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.07);
            border-radius: 0.12rem;
            margin: 0.76rem auto;
            padding: 0.34rem 0.23rem;
            box-sizing: border-box;

            .icon {
                width: 0.46rem;
                height: 0.46rem;
                display: block;
                margin: 0 auto;
            }
            &-title {
                font-size: 0.16rem;
                font-weight: 500;
                text-align: center;
                margin-top: 0.2rem;
                margin-bottom: 0.2rem;
            }
            &-item {
                font-size: 0.12rem;
                line-height: 0.16rem;
                display: flex;
                margin-bottom: 0.06rem;

                &:last-of-type {
                    margin-bottom: 0;
                }

                .label {
                    width: 0.9rem;
                    text-align: right;
                    color: #8c8c8c;
                    font-weight: 500;
                    margin-right: 0.16rem;
                }
                .value {
                    font-weight: 600;
                }
            }
            &-notice {
                width: 3.76rem;
                font-size: 0.12rem;
                text-align: center;
                opacity: 0.5;
                transform: scale(0.75);
                transform-origin: 0 0;
                margin-top: 0.2rem;
            }
        }
    }
}
</style>
