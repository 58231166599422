<template>
    <div class="contact-top" :class="{ success: isSubmitSuccess }">
        <h1 class="block-title poppins contact-top-title">{{ i18nPage.title }}</h1>
        <h2 class="block-desc contact-top-desc">{{ i18nPage.desc }}</h2>
        <img class="qr-icon" :class="{ show: !showQr }" src="@assets/images/contact/qr_icon.png" v-if="lang === 'zh' && isMobile" @click="onToggleShow(true)" />
        <div class="contact-top-form" v-if="!isSubmitSuccess">
            <contactForm @success="onSuccess" @send="onSend"></contactForm>
        </div>
        <div class="contact-top-success" v-else>
            <div class="poppins contact-top-success-title">
                <div>{{ i18nPage.thank1 }}</div>
                <div>{{ i18nPage.thank2 }}</div>
            </div>
            <div class="contact-top-success-foot">
                <div class="contact-top-success-foot-title">{{ i18nPage.alsoContact }}</div>
                <div class="contact-top-success-foot-bd">
                    <div v-for="(item, index) in i18nPage.alsoContactList" :key="index">{{ item }}</div>
                </div>
            </div>
        </div>
        <div class="contact-top-foot" v-html="i18nPage.foot"></div>

        <template v-if="lang === 'zh'">
            <div class="wechat-qr" v-if="!isMobile">
                <img src="@assets/images/contact/wechat.png" alt="" />
            </div>

            <div class="qr-modal" :class="{ show: showQr }">
                <div class="qr-modal-overlay" @click="onToggleShow(false)"></div>
                <img src="@assets/images/contact/m_wechat.png" alt="" />
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import contactForm from '../../components/contactForm.vue';
const ua = navigator.userAgent.toLowerCase();

export default {
    components: {
        contactForm,
    },
    data() {
        return {
            isSubmitSuccess: false, // 是否提交成功
            data: {},
            isMobile: (/(iPhone|iPad|iPod|iOS|Android|adr|Windows Phone|SymbianOS)/gi.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 2)) && !window.MSStream,
            showQr: false,
        };
    },
    computed: {
        ...mapState({
            lang: state => state.route.params.lang,
        }),
        i18nPage() {
            return this.$t('contactPage.top');
        },
    },
    mounted() {
        if (this.isMobile && this.lang === 'zh') {
            this.onToggleShow(true);
        }
    },
    methods: {
        onSuccess(data) {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            if (this.lang === 'zh') {
                this.isSubmitSuccess = true;
                this.$track('contactus_succsee_imp');
            } else {
                this.$emit('formSubmit', data);
            }
        },
        onSend() {
            this.$track('contactus_send_clk');
        },
        onToggleShow(status) {
            this.showQr = status;
            if (status) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
        },
    },
};
</script>

<style lang="scss">
.contact-top {
    position: relative;
    background-color: #f9f9f9;
    @media only screen and (min-width: 769px) {
        padding-top: 80px;
        padding-bottom: 50px;
        background-image: url("data:image/svg+xml,%3Csvg width='722' height='677' viewBox='0 0 722 677' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.4' fill-rule='evenodd' clip-rule='evenodd' d='M-443.821 405.493c-75.773 225.679-9.548 474.406 168.723 633.687 32.05 28.63 81.215 25.86 109.831-6.2 28.621-32.02 25.865-81.194-6.169-109.82-131.842-117.772-180.871-301.533-124.926-468.157 77.205-229.945 328.308-353.782 559.767-276.068 231.46 77.713 356.951 327.993 279.746 557.938-55.991 166.762-206.134 283.697-382.472 297.947-42.839 3.46-74.737 40.98-71.295 83.8 2.589 31.98 24.196 57.9 52.813 67.51 9.666 3.25 20.158 4.64 30.987 3.77 238.492-19.27 441.587-177.64 517.427-403.517 104.509-311.269-64.902-649.92-377.686-754.938C.157-73.568-339.312 94.225-443.821 405.493z' fill='url(%23paint0_linear_40_12646)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_40_12646' x1='-620.018' y1='930.229' x2='514.443' y2='1311.13' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23AAE5FF'/%3E%3Cstop offset='1' stop-color='%23B1F6FC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
        background-position: left bottom;
        background-repeat: no-repeat;
        background-size: 722px auto;

        &.success {
            background-image: url("data:image/svg+xml,%3Csvg width='724' height='427' viewBox='0 0 724 427' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.4' fill-rule='evenodd' clip-rule='evenodd' d='M-441.821 405.493c-75.773 225.679-9.548 474.406 168.723 633.687 32.05 28.63 81.215 25.86 109.831-6.2 28.621-32.02 25.865-81.194-6.169-109.82-131.842-117.772-180.871-301.533-124.926-468.157 77.205-229.945 328.308-353.782 559.767-276.068 231.46 77.713 356.951 327.993 279.746 557.938-55.991 166.762-206.134 283.697-382.472 297.947-42.839 3.46-74.737 40.98-71.295 83.8 2.589 31.98 24.196 57.9 52.813 67.51 9.666 3.25 20.158 4.64 30.987 3.77 238.492-19.27 441.587-177.64 517.427-403.517 104.509-311.269-64.902-649.92-377.686-754.938C2.157-73.568-337.312 94.225-441.821 405.493z' fill='url(%23paint0_linear_40_12716)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_40_12716' x1='-618.018' y1='930.229' x2='516.443' y2='1311.13' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23AAE5FF'/%3E%3Cstop offset='1' stop-color='%23B1F6FC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
        }

        &-title {
            color: #00C8C8;
        }

        &-form {
            width: 683px;
            margin: 40px auto 16px auto;
            background: #fff;
            border-radius: 20px;
            padding-bottom: 76px;
        }
        &-success {
            width: 683px;
            margin: 16px auto;
            background: #fff;
            border-radius: 5px;
            box-shadow: 0 4px 40px rgba(0, 0, 0, 0.05);
            background-image: url("data:image/svg+xml,%3Csvg width='683' height='103' viewBox='0 0 683 103' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.02' d='M-1 0l334.353 98.742a26.994 26.994 0 0 0 15.294 0L683 0' stroke='%23000' stroke-width='6' stroke-linecap='round'/%3E%3C/svg%3E");
            background-position: 0 0;
            background-size: contain;
            background-repeat: no-repeat;
            padding-top: 209px;
            padding-bottom: 44px;
            text-align: center;

            &-title {
                font-size: 28px;
                font-weight: 700;
                line-height: 1.1;
            }
            &-foot {
                margin-top: 100px;
                color: rgba(0, 0, 0, 0.5);
                &-title {
                    font-size: 14px;
                    line-height: 24px;
                }
                &-bd {
                    font-size: 16px;
                    line-height: 21px;
                    margin-top: 5px;
                }
            }
        }
        &-foot {
            width: 683px;
            margin: 0 auto;
            font-size: 12px;
            line-height: 17px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);

            span {
                color: #00C8C8;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        padding-top: 0.65rem;
        background-image: url("data:image/svg+xml,%3Csvg width='194' height='208' viewBox='0 0 194 208' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.4' fill-rule='evenodd' clip-rule='evenodd' d='M81.587 478.667c78.993 78.993 197.341 103.138 301.518 61.514 18.727-7.485 27.833-28.723 20.344-47.444-7.474-18.717-28.712-27.834-47.434-20.355-77.033 30.789-164.491 12.993-222.813-45.329-80.487-80.487-80.053-211.871.963-292.888 81.017-81.017 212.401-81.45 292.888-.963 58.371 58.37 76.134 145.892 45.276 222.963-7.501 18.722 1.6 39.965 20.311 47.465 13.979 5.595 29.386 1.933 39.403-8.084a36.375 36.375 0 0 0 8.057-12.233c41.737-104.235 17.63-222.663-61.433-301.726C369.715-27.365 192.022-26.943 82.54 82.54c-109.477 109.477-109.905 287.175-.953 396.127z' fill='url(%23paint0_linear_366_5067)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_366_5067' x1='265.255' y1='662.344' x2='662.345' y2='265.254' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23AAE5FF'/%3E%3Cstop offset='1' stop-color='%23B1F6FC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: 2rem auto;

        &.success {
            background-image: url("data:image/svg+xml,%3Csvg width='234' height='125' viewBox='0 0 234 125' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.4' fill-rule='evenodd' clip-rule='evenodd' d='M81.587 478.667c78.993 78.993 197.341 103.138 301.518 61.514 18.727-7.485 27.833-28.723 20.344-47.444-7.474-18.717-28.712-27.834-47.434-20.355-77.033 30.789-164.491 12.993-222.813-45.329-80.487-80.487-80.053-211.871.963-292.888 81.017-81.017 212.401-81.45 292.888-.963 58.371 58.37 76.134 145.892 45.276 222.963-7.501 18.722 1.6 39.965 20.311 47.465 13.979 5.595 29.386 1.933 39.403-8.084a36.375 36.375 0 0 0 8.057-12.233c41.737-104.235 17.63-222.663-61.433-301.726C369.715-27.365 192.022-26.943 82.54 82.54c-109.477 109.477-109.905 287.175-.953 396.127z' fill='url(%23paint0_linear_366_5199)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_366_5199' x1='265.255' y1='662.344' x2='662.345' y2='265.254' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23AAE5FF'/%3E%3Cstop offset='1' stop-color='%23B1F6FC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
        }
        &-title {
            font-size: 0.3rem;
            line-height: 1.1;
            font-weight: 700;
            text-align: center;
            color: #00C8C8;
        }
        &-desc {
            font-size: 0.14rem;
            line-height: 0.2rem;
            text-align: center;
            padding: 0.25rem 0.3rem 0.4rem 0.3rem;
        }
        &-form {
            padding: 0 0.36rem;
        }

        &-success {
            margin: 0 0.2rem;
            background: #fff;
            border-radius: 0.03rem;
            box-shadow: 0 0.02rem 0.2rem rgba(0, 0, 0, 0.05);
            background: url("data:image/svg+xml,%3Csvg width='337' height='50' viewBox='0 0 337 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='.02' d='M-.492 0l164.36 47.938c2.377.694 4.903.694 7.28 0L335.508 0' stroke='%23000' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E") 0 0 no-repeat;
            background-size: contain;
            text-align: center;
            padding-top: 1rem;
            padding-bottom: 0.3rem;

            &-title {
                font-size: 0.13rem;
                line-height: 0.18rem;
                font-weight: 700;
            }
            &-foot {
                margin-top: 0.4rem;
                font-size: 0.08rem;
                line-height: 1.1;
                color: rgba(0, 0, 0, 0.5);

                &-title {
                    margin-bottom: 0.02rem;
                }
            }
        }

        &-foot {
            font-size: 0.12rem;
            line-height: 0.17rem;
            padding: 0.52rem 0.3rem 0.72rem 0.3rem;
            color: rgba(0, 0, 0, 0.5);
            font-weight: 400;
            span {
                color: #00C8C8;
            }
        }
    }
    .wechat-qr {
        position: fixed;
        top: 50%;
        right: 32px;
        transform: translateY(-50%);
        img {
            width: 220px;
            height: 258px;
        }
        @media only screen and (max-width: 1300px) {
            right: 22px;
            img {
                width: 180px;
                height: 211px;
            }
        }
        @media only screen and (max-width: 1108px) {
            right: 12px;
            img {
                width: 120px;
                height: 141px;
            }
        }
    }
    .qr-modal {
        display: none;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        .qr-modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(74, 74, 74, 0.5);
        }
        img {
            width: 220px;
            height: 258px;
            z-index: 12;
            display: block;
            -webkit-user-select: none;
            user-select: none;
            -webkit-touch-callout: default;
        }
        &.show {
            display: flex;
            animation: fade_in_out 0.3s linear;
        }
    }
    .qr-icon {
        display: none;
        position: absolute;
        right: 15px;
        top: 167px;
        width: 40px;
        height: 40px;
        &.show {
            display: block;
            animation: fade_in_out 0.5s linear;
        }
    }
    @keyframes fade_in_out {
        0% {
            opacity: 0.2;
        }
        100% {
            opacity: 1;
        }
    }
}
</style>
